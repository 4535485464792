import { useAuthenticationClient } from '@api/enrollmentClient/config'

function useAuthenticationApi() {
  const client = useAuthenticationClient()

  return {
    client,
  }
}

export default useAuthenticationApi
