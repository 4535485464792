import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { userHasAccessToken } from '@utils/authentication'

export { RouteGuard }

function RouteGuard({ children }) {
  const router = useRouter()
  const [authorized, setAuthorized] = useState(false)
  const hideEnrollmentlFlow =
    process.env.NEXT_PUBLIC_HIDE_ENROLLMENT_FLOW === 'true'

  useEffect(() => {
    // on initial load - run auth check
    AuthCheck(router.asPath)
    // on initial load - run hide enrollment flow check
    EnrollmentFlowRedirect(router.asPath)

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false)
    router.events.on('routeChangeStart', hideContent)
    router.events.on('hashChangeStart', hideContent)

    // on route change complete - run auth check
    router.events.on('routeChangeComplete', AuthCheck)
    router.events.on('hashChangeComplete', AuthCheck)
    router.events.on('routeChangeComplete', EnrollmentFlowRedirect)
    router.events.on('hashChangeComplete', EnrollmentFlowRedirect)

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off('routeChangeStart', hideContent)
      router.events.off('routeChangeComplete', AuthCheck)
      router.events.off('hashChangeStart', hideContent)
      router.events.off('hashChangeComplete', AuthCheck)
      router.events.off('routeChangeComplete', EnrollmentFlowRedirect)
      router.events.off('hashChangeComplete', EnrollmentFlowRedirect)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function AuthCheck(url) {
    // redirect to login page if accessing a private page and not logged in
    let publicPaths = [
      '/',
      '/help',
      '/locations',
      '/terms',
      '/about',
      '/auth',
      '/contact-us',
      '/important-notices',
    ]

    if (hideEnrollmentlFlow) {
      publicPaths = publicPaths.filter(
        (path) => path !== '/locations' && path !== '/about'
      )
    }

    const hasTestQueryString = url.split('?')[1] === 'test=true'
    const path = url.split('?')[0].split('#')[0]

    if (
      !userHasAccessToken() &&
      !publicPaths.includes(path) &&
      !hasTestQueryString
    ) {
      setAuthorized(false)
      router.push(
        {
          pathname: '/auth',
          query: { desiredPath: path },
        },
        '/auth'
      )
    } else {
      setAuthorized(true)
    }
  }

  function EnrollmentFlowRedirect(url) {
    if (hideEnrollmentlFlow) {
      if (url.includes('enroll')) {
        router.push({
          pathname: '/',
        })
      }
    }
  }

  return authorized && children
}
