import { Enrollment } from '@clear-denver/tpc-openapi-typescript'

type EventName =
  | Enrollment.LogEntryLevelEnum.ERROR
  | Enrollment.LogEntryLevelEnum.INFO

interface Data {
  payload: {
    message: string
    stack?: string
  }
  metadata: {
    xCLEARCorrelationInfo: string
    kpi: KPI
    event: DiscreteEvent
    [key: string]: any
  }
}

export enum KPI {
  USER_INTERACTION = 'UserInteraction',
}

export enum DiscreteEvent {
  ACCEPT_COPPA_CALL_BEGINS = 'AcceptCoppaCallBegins',
  ACCEPT_COPPA_CALL_SUCCESSFUL = 'AcceptCoppaCallSuccessful',
  ACCEPT_COPPA_CALL_FAILURE = 'AcceptCoppaCallFailure',
  IDENTITY_SUBMIT_START = 'IdentitySubmitStart',
  IDENTITY_SUBMIT_SUCCESSFUL = 'IdentitySubmitSuccessful',
  IDENTITY_SUBMIT_FAILURE = 'IdentitySubmitFailure',
  TOKEN_SWITCH_BEGIN = 'TokenSwitchBegin',
  TOKEN_SWITCH_FINISHED = 'TokenSwitchFinished',
  KTN_LOOKUP_BY_APP_ID_BEGIN = 'KTNLookupByApplicationIdBegin',
  KTN_LOOKUP_BY_APP_ID_FINISHED = 'KTNLookupByApplicationIdFinished',
  KTN_LOOKUP_BY_BIOGRAPHICS_BEGIN = 'KTNLookupByBiographicsFinished',
  STATUS_LOOKUP_BY_APPLICATION_ID_BEGIN = 'StatusLookupByApplicationIdBegin',
  STATUS_LOOKUP_BY_BIOGRAPHICS_BEGIN = 'StatusLookupByBiographicsBegin',
  SEARCH_ELIGIBILITY_BY_KTN_OR_BIOGRAPHICS_BEGIN = 'SearchEligibilityByKtnOrBiographicsBegin',
  SEARCH_ELIGIBILITY_BY_KTN_OR_BIOGRAPHICS_SUCCESSFUL = 'SearchEligibilityByKtnOrBiographicsSuccess',
  SEARCH_ELIGIBILITY_BY_KTN_OR_BIOGRAPHICS_FAILURE = 'SearchEligibilityByKtnOrBiographicsFailure',
  DOCUMENT_CHECK_SUBMIT_BEGIN = 'DocumentCheckSubmitBegin',
  DOCUMENT_CHECK_SUBMIT_SUCCESSFUL = 'DocumentCheckSubmitSuccessful',
  DOCUMENT_CHECK_SUBMIT_FAILURE = 'DocumentCheckSubmitFailure',
  PAYMENT_SUBMIT_BEGIN = 'PaymentSubmitBegin',
  PAYMENT_SUBMIT_SUCCESSFUL = 'PaymentSubmitSuccessful',
  PAYMENT_SUBMIT_FAILURE = 'PaymentSubmitFailure',
  ACCEPT_TERMS_AND_CONDITIONS_BEGIN = 'AcceptTermsAndConditionsBegin',
  ACCEPT_TERMS_AND_CONDITIONS_SUCCESSFUL = 'AcceptTermsAndConditionsSuccessful',
  ACCEPT_TERMS_AND_CONDITIONS_FAILURE = 'AcceptTermsAndConditionsFailure',
  FETCH_KTN_DETAILS_BY_APP_ID_BEGIN = 'FetchKtnDetailsByAppIdBegin',
  FETCH_KTN_DETAILS_BY_APP_ID_SUCCESSFUL = 'FetchKtnDetailsByAppIdSuccessful',
  FETCH_KTN_DETAILS_BY_APP_ID_FAILURE = 'FetchKtnDetailsByAppIdFailure',
  PROVIDE_ADDRESSES_SUBMIT_BEGIN = 'ProvideAddressesSubmitBegin',
  PROVIDE_ADDRESSES_SUBMIT_SUCCESSFUL = 'ProvideAddressesSubmitSuccessful',
  PROVIDE_ADDRESSES_SUBMIT_FAILURE = 'ProvideAddressesSubmitFailure',
  UPDATE_CONTACT_APPLICANT_INFO_BEGIN = 'UpdateContactApplicantInfoBegin',
  UPDATE_CONTACT_APPLICANT_INFO_FINISHED = 'UpdateContactApplicantInfoFinished',
  UPDATE_CONTACT_PROVIDE_ADDRESSES_BEGIN = 'UpdateContactProvideAddressesBegin',
  UPDATE_CONTACT_PROVIDE_ADDRESSES_FINISHED = 'UpdateContactProvideAddressesFinished',
  UPDATE_CONTACT_GET_ENROLLMENT_BY_ID_BEGIN = 'UpdateContactGetEnrollmentByIdBegin',
  UPDATE_CONTACT_GET_ENROLLMENT_BY_ID_FINISHED = 'UpdateContactGetEnrollmentByIdFinished',
  UPDATE_CONTACT_PROVIDE_CONTACT_BEGIN = 'UpdateContactProvideContactBegin',
  UPDATE_CONTACT_PROVIDE_CONTACT_FINISHED = 'UpdateContactProvideContactFinished',
  UPDATE_CONTACT_SUBMIT_BEGIN = 'UpdateContactSubmitBegin',
  UPDATE_CONTACT_SUBMIT_SUCCESSFUL = 'UpdateContactSubmitSuccessful',
  UPDATE_CONTACT_SUBMIT_FAILURE = 'UpdateContactSubmitFailure',
  HISTORY_SUBMIT_BEGIN = 'HistorySubmitBegin',
  HISTORY_SUBMIT_FAILURE = 'HistorySubmitFailure',
  HISTORY_SUBMIT_SUCCESSFUL = 'HistorySubmitSuccessful',
  HISTORY_SUBMIMT_CONFIRM_ELIGIBILITY_BEGIN = 'HistorySubmitConfirmEligibilityBegin',
  HISTORY_SUBMIMT_CONFIRM_ELIGIBILITY_FINISHED = 'HistorySubmitConfirmEligibilityFinished',
  START_ENROLLMENT_BEGIN = 'StartEnrollmentBegin',
  START_ENROLLMENT_FINISHED = 'StartEnrollmentFinished',
  START_ENROLLMENT_ERROR = 'StartEnrollmentError',
  SUBMIT_FINALIZED_ENROLLMENT_BEGIN = 'SubmitFinalizedEnrollmentBegin',
  SUBMIT_FINALIZED_ENROLLMENT_SUCCESSFUL = 'SubmitFinalizedEnrollmentSuccessful',
  SUBMIT_FINALIZED_ENROLLMENT_FAILURE = 'SubmitFinalizedEnrollmentFailure',
  GET_NEARBY_ENROLLMENT_LOCATIONS_BEGIN = 'GetNearbyEnrollmentLocationsBegin',
  GET_NEARBY_ENROLLMENT_LOCATIONS_SUCCESSFUL = 'GetNearbyEnrollmentLocationsSuccessful',
  GET_NEARBY_ENROLLMENT_LOCATIONS_FAILURE = 'GetNearbyEnrollmentLocationsFailure',
  DELETE_DOCS_BEGIN = 'DeleteDocumentsBegin',
  DELETE_DOCS_SUCCESSFUL = 'DeleteDocumentsSuccessful',
  DELETE_DOCS_FAILURE = 'DeleteDocumentsFailure',
  SUBMIT_APPLICATION_ID_FORM_BEGIN = 'SubmitApplicationIdFormBegin',
  SUBMIT_APPLICATION_ID_FORM_SUCCESSFUL = 'SubmitApplicationIdFormSuccessful',
  SUBMIT_APPLICATION_ID_FORM_FAILURE = 'SubmitApplicationIdFormFailure',
  SUBMIT_APPLICATION_BY_BIOGRAPHICS_BEGIN = 'SubmitApplicationIdFormBegin',
  SUBMIT_APPLICATION_BY_BIOGRAPHICS_SUCCESSFUL = 'SubmitByBiographicsmSuccessful',
  SUBMIT_APPLICATION_BY_BIOGRAPHICS_FAILURE = 'SubmitByBiographicsmFailure',
  PROMOTION_OPT_IN_BEGIN = 'PromotionOptInBegin',
  PROMOTION_OPT_IN_SUCCESSFUL = 'PromotionOptInSuccessful',
  PROMOTION_OPT_IN_FAILURE = 'PromotionOptInFailure',
  RENEWAL_ELIGIBILITY_BEGIN = 'RenewalEligibilityBegin',
  RENEWAL_ELIGIBILITY_SUCCESSFUL = 'RenewalEligibilitySuccessful',
  RENEWAL_ELIGIBILITY_FAILURE = 'RenewalEligibilityFailure',
  SUBMIT_PREBIOGRAPHIC_CITIZENSHIP_BEGIN = 'SubmitPreBiographicCitizenshipBegin',
  SUBMIT_PREBIOGRAPHIC_CITIZENSHIP_SUCCESSFUL = 'SubmitPreBiographicCitizenshipSuccessful',
  SUBMIT_PREBIOGRAPHIC_CITIZENSHIP_FAILURE = 'SubmitPreBiographicCitizenshipFailure',
  PROMO_CODE_VALIDATION_BEGIN = 'PromoCodeValidationBegin',
  PROMO_CODE_VALIDATION_SUCCESSFUL = 'PromoCodeValidationSuccessful',
  PROMO_CODE_VALIDATION_FAILURE = 'PromoCodeValidationFailure',
}

export const dispatchCustomEvent = (eventName: EventName, data: Data) => {
  const event = new CustomEvent(eventName, { detail: data })
  document.dispatchEvent(event)
}
