import { v4 as uuidv4 } from 'uuid'

interface ClearCorrelationInfo {
  xCLEARCorrelationInfo: string
  sessionId: string
  requestId: string
  clientId?: string
  deviceId?: string
}

let sessionId = ''

export const getSessionId = () => sessionId || ''

export const setSessionId = (id: string): Promise<any> =>
  Promise.resolve((sessionId = id))

export const getCLEARCorrelationInfo: () => ClearCorrelationInfo = () => {
  const requestId: string = uuidv4()
  const sessionId: string = getSessionId()
  return {
    xCLEARCorrelationInfo: `[sessionId='${sessionId}'], [requestId='${requestId}'], [clientId=''], [deviceId='WEB']`,
    sessionId,
    requestId,
  }
}

export const getXCLEARCorrelationInfo: () => string = () => {
  const requestId: string = uuidv4()
  const sessionId: string = getSessionId()
  return `[sessionId='${sessionId}'], [requestId='${requestId}'], [clientId=''], [deviceId='WEB']`
}

let accessToken = ''

export const getAccessToken = () => accessToken || ''

export const setAccessToken = (token: string): Promise<any> =>
  Promise.resolve((accessToken = token))
