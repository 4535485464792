import React from 'react'
import { Flex, Box, Text } from '@clear/design-system'
import { ColorType } from '@clear/design-system/lib/theme/colors'
import CheckIcon from '@assets/svgs/check-icon'
import CircleIcon from '@assets/svgs/circle-icon'
import StrokeIcon from '@assets/svgs/stroke-icon'

type Step = {
  name: string
  hasError?: boolean
}
export interface StepperProps {
  current: number
  percent: number
  steps: Array<Step>
}

const dropShadow = 'drop-shadow(0px 0px 8px #24242325)'
const red = 'utilityDarkErrorRed'
const blue = 'brandDarkBlue'
const green = 'utilityTSASuccessGreen'
const white = 'neutralsWhite'

const StepperCircle = ({
  name,
  icon,
  color,
  index,
  isCurrent = false,
}: {
  icon?: JSX.Element
  color: ColorType
  name: string
  index: number
  isCurrent: boolean
}) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      width={['1.5rem', '2.5rem']}
      flexShrink={0}
    >
      <Flex
        height={['1.5rem', '2.5rem']}
        width={['1.5rem', '2.5rem']}
        mb={[0, 2]}
        backgroundColor={color}
        alignItems="center"
        justifyContent="center"
        data-testid={`stepper-circle-${index}`}
        borderRadius="100%"
        style={{
          filter: dropShadow,
        }}
      >
        {icon}
      </Flex>
      <Box display={['none', 'block']}>
        <Text
          color={isCurrent ? 'neutralsTextBlack' : 'neutralsTextGray'}
          fontSize={2}
          data-testid={`stepper-text-${index}`}
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {name}
        </Text>
      </Box>
    </Flex>
  )
}

const Spacer = ({
  percent,
  color,
  index,
}: {
  percent: number
  color?: ColorType
  index: number
}) => (
  <Flex
    width="100%"
    height={['0.25rem', '0.5rem']}
    mt={[2, 4]}
    mx={[1, 2]}
    backgroundColor={white}
    borderRadius={4}
    style={{
      filter: dropShadow,
    }}
  >
    <Box
      width={`${percent}%`}
      height="0.5rem"
      backgroundColor={color || blue}
      data-testid={`stepper-spacer-${index}`}
      borderRadius={4}
      style={{
        filter: dropShadow,
      }}
    />
  </Flex>
)

export const Stepper = ({ current, percent, steps }: StepperProps) => {
  return (
    <Flex
      maxWidth="stepper"
      width={['full', 'tablet', 'content']}
      margin="0 auto"
    >
      {steps.map(({ hasError, name }: Step, index) => {
        let color: ColorType =
          index < current ? green : index === current ? blue : white
        let icon

        if (index < current) {
          icon = (
            <Flex width={['1rem', 'auto']}>
              <CheckIcon />
            </Flex>
          )
        } else if (index === current) {
          icon = (
            <Flex width={['1rem', 'auto']}>
              <CircleIcon />
            </Flex>
          )
        }

        if (hasError) {
          color = red
          icon = <StrokeIcon />
        }

        return (
          <React.Fragment key={`stepper-${index}`}>
            <StepperCircle
              icon={icon}
              color={color}
              name={name}
              index={index}
              isCurrent={current === index}
            />
            {index < steps.length - 1 ? (
              <Spacer
                percent={
                  index < current ? 100 : index === current ? percent : 0
                }
                color={color}
                index={index}
              />
            ) : null}
          </React.Fragment>
        )
      })}
    </Flex>
  )
}
Stepper.displayName = 'Stepper'
export default Stepper
