import { getAccessToken } from '@api/utils'
import {
  Enrollment as EnrollmentClient,
  mocks,
} from '@clear-denver/tpc-openapi-typescript'

export const preEnrollmentConfig = new EnrollmentClient.Configuration({
  basePath: process.env.NEXT_PUBLIC_ENROLLMENT_API_ENDPOINT,
  accessToken: getAccessToken,
})

export const preEnrollmentClient = new EnrollmentClient.EnrollmentStepsApi(
  preEnrollmentConfig
)

export const mfaAuthApi = new EnrollmentClient.MultiFactorAuthApi(
  preEnrollmentConfig
)

export const enrollmentApi = new EnrollmentClient.EnrollmentApi(
  preEnrollmentConfig
)

export const promoCodeApi = new EnrollmentClient.PromoCodeApi(
  preEnrollmentConfig
)

export const authenticationClient = new EnrollmentClient.AuthenticationApi(
  new EnrollmentClient.Configuration({
    basePath: process.env.NEXT_PUBLIC_ENROLLMENT_API_ENDPOINT,
  })
)

export const enrollmentHelperApi = new EnrollmentClient.EnrollmentHelperApi(
  preEnrollmentConfig
)

export const mockEnrollmentClient = new mocks.EnrollmentStepsApi()

export type Client =
  | EnrollmentClient.EnrollmentStepsApi
  | mocks.EnrollmentStepsApi

export const useClient = (isTest: boolean) =>
  isTest ? mockEnrollmentClient : preEnrollmentClient

export const useAuthenticationClient = () => {
  return authenticationClient
}

export const initialState: EnrollmentClient.Enrollment = {
  ...mocks.generateNewEnrollment(),
  eligibility: {},
  citizenship: {
    birthCountry: '',
    birthLocality: '',
    birthSubdivision: '',
    citizenshipCountry: '',
    nonImmigrantVisaStatus: '',
  },
  documentCheck: {
    photoId: '',
    document: '',
  },
  identityHints: {
    everUsedPreviousName: false,
    everUsedAlias: false,
  },
  biographicInfo: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    gender: '',
    dateOfBirth: new Date(),
    noMiddleName: false,
  },
  contact: {
    email: '',
    emailVerification: '',
    phoneNumber: '',
    preferredMethodOfContact: 'Email',
  },
  attributes: {
    previousNames: [],
    aliases: [],
    eyeColor: '',
    hairColor: '',
    heightFeet: 0,
    heightInches: 0,
    weightPounds: 0,
  },
  legal: {
    usCitizenOrNationalOrLPR: false,
    disqualifyingFelonyPartA: false,
    disqualifyingFelonyPartB: false,
    releasedFromIncarcerationPartB: false,
    wantedOrUnderIndictment: false,
    mentalCapacityLegalHistory: false,
  },
  finalized: {
    enrollmentLocationId: '',
  },
  addresses: {
    mailingAddress: {
      country: '',
      lineOne: '',
      lineTwo: '',
      locality: '',
      subdivision: '',
      postalCode: '',
    },
    useDifferentResidentialAddress: false,
    atCurrentAddressMoreThanFiveYears: true,
  },
  documents: {
    linkingDocExpectedCount: 0,
    uploadedFiles: [],
  },
}
