import React from 'react'
import { Flex, Box, Link } from '@clear/design-system'
import NextLink from 'next/link'

type Link = {
  text: string
  href: string
  id?: string
}

export interface FooterProps {
  links: Array<Link>
  texts: Array<Link>
  logo: JSX.Element
}

export const Footer = ({ links, texts, logo }: FooterProps) => {
  const Logo = () => logo || null
  return (
    <Flex
      as="footer"
      justifyContent="center"
      data-cy="bottom-nav"
      backgroundColor="neutralsGrayWash"
    >
      <Flex
        width={['100%', 'header']}
        flexDirection={['column', 'row']}
        justifyContent={['flex-start', 'space-between']}
        py={[5, 9]}
        px={[4, null, 5]}
      >
        <Flex
          order={[0, 2]}
          justifyContent={['flex-start', 'flex-end']}
          alignSelf={['left', 'flex-start']}
          mb={[5, 0]}
          data-testid="footer-logo"
        >
          <Logo />
        </Flex>
        <Flex flexDirection="column">
          <Box>
            {links.map(({ text, href, id }: Link, index) => (
              <NextLink href={href} passHref key={`footer-link-${index}`}>
                <Link
                  mb={[index < links.length - 1 ? 3 : 0, 3]}
                  mr={[0, 7]}
                  display={['block', 'inline-block']}
                  data-testid={`footer-link-${index}`}
                  id={id}
                  fontSize={4}
                  fontWeight="medium"
                  variant="regular"
                  sx={{
                    textTransform: 'uppercase',
                  }}
                >
                  {text}
                </Link>
              </NextLink>
            ))}
          </Box>
          <Box mt={5}>
            {texts.map(({ text, href }: Link, index) => (
              <Link
                color="neutralsTextGray"
                data-testid={`footer-text-${index}`}
                href={href}
                target="blank"
                variant="regular"
                key={`fine-print-${index}`}
                display="block"
              >
                {text}
              </Link>
            ))}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

Footer.displayName = 'Footer'
export default Footer
