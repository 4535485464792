import { theme } from '@clear/design-system'
import { ColorType, Theme } from '@clear/design-system/lib/theme/theme'

export type TpcColors = keyof typeof colors

// Some ADA components reference theme colors directly,
// so these overrides don't work for all components
export const colors = {
  ...(theme.colors as Record<ColorType, string>),

  // Overriding keys from CLEAR Design System
  brandMidnightBlue: '#005288',
  brandDarkBlue: '#005288',

  // TSA specific keys. These require type casting
  clearMidnightBlue: '#041A55',
  tsaGreen: '#51A831',
  dhsBlue: '#005288',
  utilityLinkBlue: '#005288',
}

export const space: Array<string> = [
  ...(theme.space as Array<string>), // ada values
  '6rem', // 96px
]

export const card = {
  primary: {
    borderRadius: '1rem',
  },
  secondary: {
    borderRadius: '1rem',
  },
}

export const sizes: Record<string, string> = {
  ...(theme.sizes as Record<string, string>),
  landingPageContent: '78.125rem',
}
const tpcTheme: Theme = {
  ...theme,
  colors,
  space,
  sizes,
  card,
}

export default tpcTheme
