import React from 'react'
import { Box, BoxProps, Flex, Text, TextProps } from '@clear/design-system'
import { FlexProps } from 'styled-system'

export type BannerProps = BoxProps & {
  heading?: string
  subHeading?: string
  containerProps?: FlexProps
  textContainerProps?: FlexProps
  headingProps?: TextProps
  subHeadingProps?: TextProps
  showGradient?: boolean
  gradientStart?: string
}

export const Banner = ({
  heading,
  subHeading,
  backgroundImage,
  textContainerProps,
  headingProps,
  subHeadingProps,
  showGradient,
  gradientStart = '50%',
  children,
  ...props
}: BannerProps) => {
  return (
    <Flex
      justifyContent="center"
      position="relative"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundImage={`url('${backgroundImage}')`}
      {...props}
    >
      <Box width={['100%', 'header']}>
        <Flex
          position="relative"
          zIndex={1}
          flexDirection="column"
          justifyContent="center"
          height="100%"
          py={[6, null, 5]}
          px={[7, null, 5]}
          {...textContainerProps}
        >
          {heading && (
            <Text
              as="p"
              variant="display"
              maxWidth={['100%', '40rem']}
              fontSize={[4, 7, 8]}
              py={2}
              color="brandDarkBlue"
              {...headingProps}
            >
              {heading}
            </Text>
          )}
          {subHeading && (
            <Text
              as="p"
              variant="regular"
              maxWidth="30rem"
              fontSize={[0, 3, 4]}
              py={2}
              color="neutralsTextBlack"
              {...subHeadingProps}
            >
              {subHeading}
            </Text>
          )}
          {children}
        </Flex>
        {showGradient && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            zIndex={0}
            width="full"
            height="full"
            background={`linear-gradient(90deg, rgba(249, 249, 249, 0.97) ${gradientStart}, rgba(255, 255, 255, 0.0001) 82.41%)`}
          />
        )}
      </Box>
    </Flex>
  )
}
