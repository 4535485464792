import React from 'react'
import Head from 'next/head'

/**
 * Meta data component
 */
const Meta = () => {
  return (
    <React.Fragment>
      <Head>
        <meta charSet="utf-8" />
      </Head>
    </React.Fragment>
  )
}

export default Meta
