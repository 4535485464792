import React, { HTMLAttributes, ReactNode } from 'react'
import {
  Flex,
  FlexProps,
  Box,
  BoxProps,
  Card,
  Text,
  IconName,
} from '@clear/design-system'
import { ColorType } from '@clear/design-system/lib/theme/colors'

export type PlanCardHeaderProps = FlexProps & HTMLAttributes<HTMLDivElement>

export type PlanCardListItem = {
  text: string
  color: ColorType
  icon?: IconName
  iconColor?: ColorType
}
export type PlanCardList = Array<PlanCardListItem>

export type ListProps = BoxProps & { items: PlanCardList }

const List = ({ items, ...props }: ListProps) => {
  return (
    <Box {...props}>
      <Box
        as="ul"
        paddingLeft="2rem"
        fontSize={2}
        data-cy="plan-card-list"
        __css={{
          listStyle: 'outside disc',
        }}
      >
        {items.map(({ text, color }: PlanCardListItem, key) => (
          <Box key={key} as="li" display="list-item" mb={4}>
            <Text color={color} py={2}>
              {text}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const CardHeader = (props: PlanCardHeaderProps) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      px={7}
      py={2}
      borderTopLeftRadius="1rem"
      borderTopRightRadius="1rem"
      borderBottomWidth={1}
      {...props}
    />
  )
}

export type PlanCardProps = BoxProps &
  HTMLAttributes<HTMLDivElement> & {
    header?: ReactNode
    headerBg?: ColorType
    headerColor?: ColorType
    subHeaderText?: string
    upperBody?: ReactNode
    price?: number
    priceColor?: ColorType
    body?: ReactNode
    buttons?: Array<ReactNode>
    list?: PlanCardList
    headerProps?: PlanCardHeaderProps
  }

export const PlanCard = ({
  header,
  headerBg = 'neutralsWhite',
  headerColor = 'neutralsTextGray',
  subHeaderText,
  upperBody,
  price,
  priceColor,
  body,
  list,
  buttons,
  headerProps,
  ...props
}: PlanCardProps) => {
  return (
    <Box borderRadius="1rem" data-cy="plan-card" {...props}>
      <Card variant="secondary" position="relative">
        <CardHeader
          bg={headerBg}
          color={headerColor}
          marginTop="-2rem"
          marginX="-2rem"
          {...headerProps}
        >
          {header}
        </CardHeader>
        <Flex flexDirection="column" justifyContent="center" textAlign="center">
          {subHeaderText && (
            <Text variant="regular" p={5} color="neutralsTextGray">
              {subHeaderText}
            </Text>
          )}
          {upperBody && (
            <Flex
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
            >
              {upperBody}
            </Flex>
          )}
          {price && (
            <Box color={priceColor || 'brandDarkBlue'} mt={4}>
              <Text fontSize={7}>${price}</Text>
            </Box>
          )}
          {body && (
            <Flex
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
            >
              {body}
            </Flex>
          )}
          {buttons && (
            <Flex justifyContent="center" mt={3} data-cy="button-row">
              {buttons}
            </Flex>
          )}
        </Flex>
        {list && <List items={list} />}
      </Card>
    </Box>
  )
}
