import React, { useState, useRef, useEffect } from 'react'
import { HeadTitle } from '@components/layout/HeadTitle'
import Meta from '../Meta'
import {
  SeedNewEnrollment,
  SeedFullEnrollment,
} from '@components/testing/SeedEnrollment'
import { Flex, Box, Checkbox } from '@clear/design-system'
import { Footer, Header } from '@components/storybook'
import { footerArgs } from '@utils/footerArgs'
import { headerArgs } from '@utils/headerArgs'
import { userHasAccessToken } from '@utils/authentication'
import useEnrollmentApi from '@hooks/useEnrollmentApi'
import useAuthenticationApi from '@hooks/useAuthenticationApi'
import {
  getXCLEARCorrelationInfo,
  setAccessToken,
  setSessionId,
} from '@api/utils'
import { useEnrollmentContext } from '@components/EnrollmentContext'

const Layout: React.FC = ({ children }) => {
  const [isHeaderMenuOpen, setIsHeaderMenuOpen] = useState(false)
  const { ambassadorSecurityLoginActive, setAmbassadorSecurityLoginActive } =
    useEnrollmentContext()

  const menuRef = useRef(null)
  const { clearState: clearEnrollmentState } = useEnrollmentApi()

  const { client } = useAuthenticationApi()
  async function securityAmbassadorLogin() {
    try {
      const { token, id } = await client.login({
        xCLEARCorrelationInfo: getXCLEARCorrelationInfo(),
        credentialsForm: {
          username: process.env.NEXT_PUBLIC_SECURITY_AMBASSADOR_USERNAME,
          password: process.env.NEXT_PUBLIC_SECURITY_AMBASSADOR_PASSWORD,
        },
      })
      setAccessToken(token)
      setSessionId(id)
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    if (ambassadorSecurityLoginActive) {
      securityAmbassadorLogin()
    } else {
      setAccessToken('')
      setSessionId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ambassadorSecurityLoginActive])

  const showAmbassadorSecurityLoginCheckBox =
    process.env.NEXT_PUBLIC_APP_ENV === 'test' ||
    process.env.NODE_ENV === 'development'

  return (
    <Flex
      flexDirection="column"
      minHeight="viewportHeight"
      justifyContent="space-between"
      onClick={(event) => {
        if (isHeaderMenuOpen && !menuRef.current.contains(event.target)) {
          setIsHeaderMenuOpen(false)
        }
      }}
    >
      <Meta />
      <Header
        authenticated={userHasAccessToken()}
        {...headerArgs}
        isMenuOpen={isHeaderMenuOpen}
        setIsMenuOpen={setIsHeaderMenuOpen}
        menuRef={menuRef}
        clearState={clearEnrollmentState}
      />
      <HeadTitle
        title="TSA PreCheck® Enrollment Provided by CLEAR"
        description="Enjoy smoother security for five years with TSA PreCheck®-now available through CLEAR, no appointment necessary. Enroll or renew today!"
      />
      <Box as="main">
        {showAmbassadorSecurityLoginCheckBox && (
          <Checkbox
            data-cy="activate-security-ambassador"
            alt="show ambassador security login checkbox"
            checked={ambassadorSecurityLoginActive}
            onChange={() =>
              setAmbassadorSecurityLoginActive(!ambassadorSecurityLoginActive)
            }
            text="Activate Security Ambassador Login"
          />
        )}
        {children || ''}
      </Box>
      <Footer {...footerArgs} />
      {process.env.NEXT_PUBLIC_APP_ENV === 'test' ||
      process.env.NODE_ENV === 'development' ? (
        <div className="fixed bottom-0 left-0 block mb-3 ml-3">
          <SeedNewEnrollment />
          <SeedFullEnrollment />
        </div>
      ) : null}
    </Flex>
  )
}

export default Layout
