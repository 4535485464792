import React from 'react'

const CircleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="stepper-circle-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.875C6.9604 2.875 2.875 6.9604 2.875 12C2.875 17.0396 6.9604 21.125 12 21.125C17.0396 21.125 21.125 17.0396 21.125 12C21.125 6.9604 17.0396 2.875 12 2.875ZM1.125 12C1.125 5.9939 5.9939 1.125 12 1.125C18.0061 1.125 22.875 5.9939 22.875 12C22.875 18.0061 18.0061 22.875 12 22.875C5.9939 22.875 1.125 18.0061 1.125 12Z"
      fill="#FFF"
    />
  </svg>
)

export default CircleIcon
