import React from 'react'

const CheckIcon = () => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="stepper-check-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6187 0.381282C17.9604 0.72299 17.9604 1.27701 17.6187 1.61872L6.61872 12.6187C6.27701 12.9604 5.72299 12.9604 5.38128 12.6187L0.381282 7.61872C0.0395728 7.27701 0.0395728 6.72299 0.381282 6.38128C0.72299 6.03957 1.27701 6.03957 1.61872 6.38128L6 10.7626L16.3813 0.381282C16.723 0.0395728 17.277 0.0395728 17.6187 0.381282Z"
      fill="#FFF"
    />
  </svg>
)

export default CheckIcon
