import React from 'react'
import { Box } from '@clear/design-system'
import { colors, TpcColors } from '../../styles/theme'

const ClearLogo = ({
  width,
  color = 'clearMidnightBlue',
}: {
  width: Array<string>
  color?: TpcColors
}) => {
  const fill = colors[color]
  return (
    <Box width={width}>
      <svg viewBox="0 0 281 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9857 46.9207C12.6347 46.9207 13.9714 45.582 13.9714 43.9307C13.9714 42.2794 12.6347 40.9407 10.9857 40.9407C9.33675 40.9407 8 42.2794 8 43.9307C8 45.582 9.33675 46.9207 10.9857 46.9207Z"
          fill={fill}
        />
        <path
          d="M24.4193 46.9207C26.0683 46.9207 27.405 45.582 27.405 43.9307C27.405 42.2794 26.0683 40.9407 24.4193 40.9407C22.7704 40.9407 21.4336 42.2794 21.4336 43.9307C21.4336 45.582 22.7704 46.9207 24.4193 46.9207Z"
          fill={fill}
        />
        <path
          d="M18.9164 55.7412C20.5654 55.7412 21.9021 54.4026 21.9021 52.7513C21.9021 51.1 20.5654 49.7613 18.9164 49.7613C17.2674 49.7613 15.9307 51.1 15.9307 52.7513C15.9307 54.4026 17.2674 55.7412 18.9164 55.7412Z"
          fill={fill}
        />
        <path
          d="M28.5785 59.5715C30.2274 59.5715 31.5642 58.2328 31.5642 56.5815C31.5642 54.9302 30.2274 53.5915 28.5785 53.5915C26.9295 53.5915 25.5927 54.9302 25.5927 56.5815C25.5927 58.2328 26.9295 59.5715 28.5785 59.5715Z"
          fill={fill}
        />
        <path
          d="M29.1705 70.015C30.8195 70.015 32.1562 68.6763 32.1562 67.025C32.1562 65.3737 30.8195 64.0351 29.1705 64.0351C27.5215 64.0351 26.1848 65.3737 26.1848 67.025C26.1848 68.6763 27.5215 70.015 29.1705 70.015Z"
          fill={fill}
        />
        <path
          d="M18.9164 38.0915C20.5654 38.0915 21.9021 36.7529 21.9021 35.1016C21.9021 33.4503 20.5654 32.1116 18.9164 32.1116C17.2674 32.1116 15.9307 33.4503 15.9307 35.1016C15.9307 36.7529 17.2674 38.0915 18.9164 38.0915Z"
          fill={fill}
        />
        <path
          d="M17.6983 26.5049C19.3472 26.5049 20.684 25.1663 20.684 23.5149C20.684 21.8636 19.3472 20.525 17.6983 20.525C16.0493 20.525 14.7125 21.8636 14.7125 23.5149C14.7125 25.1663 16.0493 26.5049 17.6983 26.5049Z"
          fill={fill}
        />
        <path
          d="M28.5785 34.1803C30.2274 34.1803 31.5642 32.8416 31.5642 31.1903C31.5642 29.539 30.2274 28.2003 28.5785 28.2003C26.9295 28.2003 25.5927 29.539 25.5927 31.1903C25.5927 32.8416 26.9295 34.1803 28.5785 34.1803Z"
          fill={fill}
        />
        <path
          d="M28.9639 24.1441C30.6129 24.1441 31.9496 22.8054 31.9496 21.1541C31.9496 19.5028 30.6129 18.1642 28.9639 18.1642C27.315 18.1642 25.9782 19.5028 25.9782 21.1541C25.9782 22.8054 27.315 24.1441 28.9639 24.1441Z"
          fill={fill}
        />
        <path
          d="M39.0775 26.5049C40.7264 26.5049 42.0632 25.1663 42.0632 23.5149C42.0632 21.8636 40.7264 20.525 39.0775 20.525C37.4285 20.525 36.0917 21.8636 36.0917 23.5149C36.0917 25.1663 37.4285 26.5049 39.0775 26.5049Z"
          fill={fill}
        />
        <path
          d="M52.0937 26.5049C53.7426 26.5049 55.0794 25.1663 55.0794 23.5149C55.0794 21.8636 53.7426 20.525 52.0937 20.525C50.4447 20.525 49.1079 21.8636 49.1079 23.5149C49.1079 25.1663 50.4447 26.5049 52.0937 26.5049Z"
          fill={fill}
        />
        <path
          d="M61.9516 24.0161C63.6006 24.0161 64.9374 22.6775 64.9374 21.0262C64.9374 19.3748 63.6006 18.0362 61.9516 18.0362C60.3027 18.0362 58.9659 19.3748 58.9659 21.0262C58.9659 22.6775 60.3027 24.0161 61.9516 24.0161Z"
          fill={fill}
        />
        <path
          d="M62.6289 34.385C64.2778 34.385 65.6146 33.0463 65.6146 31.395C65.6146 29.7437 64.2778 28.4051 62.6289 28.4051C60.9799 28.4051 59.6431 29.7437 59.6431 31.395C59.6431 33.0463 60.9799 34.385 62.6289 34.385Z"
          fill={fill}
        />
        <path
          d="M72.2931 38.0915C73.942 38.0915 75.2788 36.7529 75.2788 35.1016C75.2788 33.4503 73.942 32.1116 72.2931 32.1116C70.6441 32.1116 69.3073 33.4503 69.3073 35.1016C69.3073 36.7529 70.6441 38.0915 72.2931 38.0915Z"
          fill={fill}
        />
        <path
          d="M72.2931 55.7412C73.942 55.7412 75.2788 54.4026 75.2788 52.7513C75.2788 51.1 73.942 49.7613 72.2931 49.7613C70.6441 49.7613 69.3073 51.1 69.3073 52.7513C69.3073 54.4026 70.6441 55.7412 72.2931 55.7412Z"
          fill={fill}
        />
        <path
          d="M62.6289 59.6013C64.2778 59.6013 65.6146 58.2627 65.6146 56.6114C65.6146 54.9601 64.2778 53.6214 62.6289 53.6214C60.9799 53.6214 59.6431 54.9601 59.6431 56.6114C59.6431 58.2627 60.9799 59.6013 62.6289 59.6013Z"
          fill={fill}
        />
        <path
          d="M73.6219 67.3364C75.2709 67.3364 76.6076 65.9977 76.6076 64.3464C76.6076 62.6951 75.2709 61.3565 73.6219 61.3565C71.973 61.3565 70.6362 62.6951 70.6362 64.3464C70.6362 65.9977 71.973 67.3364 73.6219 67.3364Z"
          fill={fill}
        />
        <path
          d="M52.0937 67.3364C53.7426 67.3364 55.0794 65.9977 55.0794 64.3464C55.0794 62.6951 53.7426 61.3565 52.0937 61.3565C50.4447 61.3565 49.1079 62.6951 49.1079 64.3464C49.1079 65.9977 50.4447 67.3364 52.0937 67.3364Z"
          fill={fill}
        />
        <path
          d="M39.0775 67.3364C40.7264 67.3364 42.0632 65.9977 42.0632 64.3464C42.0632 62.6951 40.7264 61.3565 39.0775 61.3565C37.4285 61.3565 36.0917 62.6951 36.0917 64.3464C36.0917 65.9977 37.4285 67.3364 39.0775 67.3364Z"
          fill={fill}
        />
        <path
          d="M17.6109 67.3364C19.2599 67.3364 20.5967 65.9977 20.5967 64.3464C20.5967 62.6951 19.2599 61.3565 17.6109 61.3565C15.962 61.3565 14.6252 62.6951 14.6252 64.3464C14.6252 65.9977 15.962 67.3364 17.6109 67.3364Z"
          fill={fill}
        />
        <path
          d="M61.9516 70.015C63.6006 70.015 64.9374 68.6763 64.9374 67.025C64.9374 65.3737 63.6006 64.0351 61.9516 64.0351C60.3027 64.0351 58.9659 65.3737 58.9659 67.025C58.9659 68.6763 60.3027 70.015 61.9516 70.015Z"
          fill={fill}
        />
        <path
          d="M45.5472 75.2869C47.1962 75.2869 48.5329 73.9482 48.5329 72.2969C48.5329 70.6456 47.1962 69.307 45.5472 69.307C43.8983 69.307 42.5615 70.6456 42.5615 72.2969C42.5615 73.9482 43.8983 75.2869 45.5472 75.2869Z"
          fill={fill}
        />
        <path
          d="M56.272 80.0021C57.9209 80.0021 59.2577 78.6635 59.2577 77.0122C59.2577 75.3609 57.9209 74.0222 56.272 74.0222C54.623 74.0222 53.2862 75.3609 53.2862 77.0122C53.2862 78.6635 54.623 80.0021 56.272 80.0021Z"
          fill={fill}
        />
        <path
          d="M34.9588 80.0021C36.6078 80.0021 37.9445 78.6635 37.9445 77.0122C37.9445 75.3609 36.6078 74.0222 34.9588 74.0222C33.3098 74.0222 31.9731 75.3609 31.9731 77.0122C31.9731 78.6635 33.3098 80.0021 34.9588 80.0021Z"
          fill={fill}
        />
        <path
          d="M66.8774 46.9207C68.5264 46.9207 69.8632 45.582 69.8632 43.9307C69.8632 42.2794 68.5264 40.9407 66.8774 40.9407C65.2285 40.9407 63.8917 42.2794 63.8917 43.9307C63.8917 45.582 65.2285 46.9207 66.8774 46.9207Z"
          fill={fill}
        />
        <path
          d="M80.2365 46.9207C81.8855 46.9207 83.2222 45.582 83.2222 43.9307C83.2222 42.2794 81.8855 40.9407 80.2365 40.9407C78.5875 40.9407 77.2508 42.2794 77.2508 43.9307C77.2508 45.582 78.5875 46.9207 80.2365 46.9207Z"
          fill={fill}
        />
        <path
          d="M73.6198 26.5049C75.2688 26.5049 76.6055 25.1663 76.6055 23.5149C76.6055 21.8636 75.2688 20.525 73.6198 20.525C71.9708 20.525 70.6341 21.8636 70.6341 23.5149C70.6341 25.1663 71.9708 26.5049 73.6198 26.5049Z"
          fill={fill}
        />
        <path
          d="M45.5472 18.5587C47.1962 18.5587 48.5329 17.22 48.5329 15.5687C48.5329 13.9174 47.1962 12.5788 45.5472 12.5788C43.8983 12.5788 42.5615 13.9174 42.5615 15.5687C42.5615 17.22 43.8983 18.5587 45.5472 18.5587Z"
          fill={fill}
        />
        <path
          d="M34.8757 13.9799C36.5247 13.9799 37.8615 12.6413 37.8615 10.99C37.8615 9.33865 36.5247 8 34.8757 8C33.2268 8 31.89 9.33865 31.89 10.99C31.89 12.6413 33.2268 13.9799 34.8757 13.9799Z"
          fill={fill}
        />
        <path
          d="M56.3018 13.9799C57.9507 13.9799 59.2875 12.6413 59.2875 10.99C59.2875 9.33865 57.9507 8 56.3018 8C54.6528 8 53.3161 9.33865 53.3161 10.99C53.3161 12.6413 54.6528 13.9799 56.3018 13.9799Z"
          fill={fill}
        />
        <path
          d="M112.72 44.0011C112.72 35.4598 119.213 28.9724 127.708 28.9724C130.318 28.9465 132.889 29.6037 135.168 30.8788C137.446 32.154 139.353 34.0029 140.698 36.2425L135.726 39.3711C134.997 37.8825 133.859 36.6335 132.445 35.7713C131.032 34.909 129.401 34.4695 127.746 34.5044C122.379 34.5044 118.448 38.7079 118.448 44.0011C118.448 49.1684 122.341 53.438 127.669 53.438C129.435 53.4733 131.171 52.9769 132.652 52.013C134.133 51.0491 135.29 49.6621 135.975 48.0318L141.139 50.8042C139.906 53.304 137.992 55.4035 135.618 56.8598C133.244 58.3161 130.507 59.0699 127.723 59.034C118.902 59.034 112.734 52.3034 112.734 44.0075"
          fill={fill}
        />
        <path
          d="M151.604 29.3797V58.6352H170.798V53.148H157.294V29.3797H151.604Z"
          fill={fill}
        />
        <path
          d="M181.913 29.3797V58.6352H201.593V53.2674H187.565V46.6136H198.986V41.282H187.565V34.7092H201.593V29.3797H181.913Z"
          fill={fill}
        />
        <path
          d="M223.038 29.3797L211.981 58.6352H217.786L219.703 53.3036H231.923L233.839 58.6352H239.649L228.592 29.3797H223.038ZM225.843 36.5432L230.049 48.1853H221.6L225.843 36.5432Z"
          fill={fill}
        />
        <path
          d="M255.887 34.5897V44.2549H261.658C265.383 44.2549 267.11 41.9281 267.11 39.3263C267.11 36.4601 265.264 34.5961 261.658 34.5961L255.887 34.5897ZM250.239 29.3797H262.101C268.833 29.3797 272.839 33.7068 272.839 39.2815C272.877 41.0599 272.417 42.8135 271.511 44.3432C270.604 45.873 269.288 47.1176 267.711 47.9358L273.484 58.6352H267.149L262.463 49.4158H255.887V58.6352H250.239V29.3797Z"
          fill={fill}
        />
      </svg>
    </Box>
  )
}

export default ClearLogo
