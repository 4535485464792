import React, { useState } from 'react'
import { Flex, Box, Text, Icon, ColorType } from '@clear/design-system'

import USFlagIcon from '@assets/svgs/us-flag-icon'
import GreenLockIcon from '@assets/svgs/green-lock-icon'
import BlueGovBldgIcon from '@assets/svgs/blue-gov-bldg-icon'
import { colors } from '../../../styles/theme'

export const GovernmentSecurityBanner = () => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false)

  const handleOnClick = () => {
    setShouldShowDrawer((prevState) => !prevState)
  }
  return (
    <Box backgroundColor="neutralsBackgroundGray">
      <Flex
        py={4}
        px={[4, 9]}
        onClick={handleOnClick}
        sx={{ cursor: 'pointer' }}
      >
        <Box flex="0 0 1.5rem">
          <USFlagIcon />
        </Box>
        <Text as="div" fontSize={0} fontWeight="300">
          An official website of the United States government&nbsp;
          <Text display="inline-block" color="brandDarkBlue">
            Here's how you know
          </Text>
        </Text>
        <Box position="relative" ml="0.5rem" mb="0" flex="0">
          <Box position="absolute" top="-0.25rem" left="-0.5rem">
            <Icon
              name={shouldShowDrawer ? 'drop-up' : 'drop-down'}
              color={colors.brandMidnightBlue as ColorType}
            />
          </Box>
        </Box>
      </Flex>
      {shouldShowDrawer && (
        <Box
          display={['block', 'flex']}
          justifyContent="center"
          pt={2}
          px={4}
          pb="1.5rem"
          mx={1}
          fontSize={1}
        >
          <Flex width="100%" maxWidth="37rem" mr={[0, 5]}>
            <Box flex="0 2.5rem" mr={5}>
              <BlueGovBldgIcon />
            </Box>
            <Box flex="1">
              <Text
                as="h3"
                mb={2}
                fontFamily="medium"
                fontSize={1}
                fontWeight="700"
              >
                The .gov means it’s official.
              </Text>
              <Text as="p" fontWeight="300">
                Federal government websites often end in .gov or .mil. Before
                sharing sensitive information, make sure you’re on a federal
                government site.
              </Text>
            </Box>
          </Flex>
          <Flex width="100%" maxWidth="37rem" mt={[5, 0]}>
            <Box flex="0 2.5rem" mr={5}>
              <GreenLockIcon />
            </Box>
            <Box flex="1">
              <Text
                as="h3"
                mb={2}
                fontFamily="medium"
                fontSize={1}
                fontWeight="700"
              >
                The site is secure.
              </Text>
              <Text as="p" fontWeight="300">
                The https:// ensures that you are connecting to the official
                website and that any information you provide is encrypted and
                transmitted securely.
              </Text>
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  )
}
