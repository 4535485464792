import React, { useCallback, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { ThemeProvider } from 'styled-components'
import {
  getXCLEARCorrelationInfo,
  getCLEARCorrelationInfo,
  getAccessToken,
} from '@api/utils'
import {
  useEnrollmentContext,
  EnrollmentProvider,
} from '@components/EnrollmentContext'
import { Enrollment as EnrollmentClient } from '@clear-denver/tpc-openapi-typescript'

import '@clear/design-system/assets/fonts/fonts.css'
import { GlobalStyle } from '@clear/design-system'

import Layout from '@components/shared/Layout'
import '../src/styles/tailwind.css'
import '../src/styles/amplifyoverrides.css'
import theme from 'src/styles/theme'
import { Amplify } from 'aws-amplify'
import awsConfig from '../aws_amplify_config'
import '@aws-amplify/ui-react/styles.css'
import { Authenticator } from '@aws-amplify/ui-react'
import { RouteGuard } from '@components/RouteGuard'
import axios from 'axios'

Amplify.configure(awsConfig)

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const [errorSent, setErrorSent] = useState(false)
  const [infoSent, setInfoSent] = useState(false)
  const { state } = useEnrollmentContext()
  const { sessionId, requestId } = getCLEARCorrelationInfo()

  if (process.browser) {
    router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0)
    })
  }

  const postLoggingEndpoint = async (arg: any) => {
    try {
      await axios.post(
        `${process.env.NEXT_PUBLIC_ENROLLMENT_API_ENDPOINT}/public/reporting/logging`,
        arg,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Clear-Correlationinfo': getXCLEARCorrelationInfo(),
            Authorization: getAccessToken(),
          },
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  const shipErrorToApi = useCallback(
    (evt: any) => {
      const { payload = {}, metadata = {} } = evt?.detail
      if (errorSent) {
        setErrorSent(false)
      } else {
        const evtError = {
          createdTime: new Date(),
          level: EnrollmentClient.LogEntryLevelEnum.ERROR,
          enrollmentId: state.id,
          payload,
          metadata: {
            url: router.route,
            enrollmentId: state.id,
            sessionId,
            requestId,
            ...metadata,
          },
        }
        try {
          setErrorSent(true)
          postLoggingEndpoint(evtError)
        } catch (e) {
          console.log('Unable to log error.', e)
          console.log(evt)
        }
      }
      return
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorSent, state.id]
  )

  // TODO: Analytics API currently throws 403, so commenting out the below for now. -KN 1/31/24
  const shipInfoToApi = useCallback(
    async ({ detail }) => {
      const { payload = {}, metadata = {} } = detail
      if (infoSent) {
        setInfoSent(false)
      } else {
        const evtInfo = {
          createdTime: new Date(),
          level: EnrollmentClient.LogEntryLevelEnum.INFO,
          enrollmentId: state.id,
          payload,
          metadata: {
            sessionId,
            requestId,
            enrollmentId: state.id,
            userAgent: window.navigator.userAgent,
            url: router.route,
            ...metadata,
          },
        }
        try {
          setInfoSent(true)
          postLoggingEndpoint(evtInfo)
        } catch (e) {
          console.log('Unable to send info log.', e)
        }
      }
      return
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoSent, state.id]
  )

  useEffect(() => {
    const listener = (data) => {
      shipInfoToApi(data)
    }
    document.addEventListener(EnrollmentClient.LogEntryLevelEnum.INFO, listener)
    return () =>
      document.removeEventListener(
        EnrollmentClient.LogEntryLevelEnum.INFO,
        listener
      )
  }, [shipInfoToApi])

  useEffect(() => {
    const listener = (event: CustomEvent) => {
      shipErrorToApi(event)
    }
    document.addEventListener(
      EnrollmentClient.LogEntryLevelEnum.ERROR,
      listener
    )
    return () => {
      document.removeEventListener(
        EnrollmentClient.LogEntryLevelEnum.ERROR,
        listener
      )
    }
  }, [shipErrorToApi])

  const Gtm = dynamic(() => import('@components/Gtm'), {
    ssr: false,
  })

  return (
    <>
      <Authenticator.Provider>
        <ThemeProvider theme={theme}>
          <EnrollmentProvider>
            <Layout>
              <RouteGuard>
                <Gtm />
                <Component {...pageProps} />
              </RouteGuard>
            </Layout>
          </EnrollmentProvider>
          <GlobalStyle />
        </ThemeProvider>
      </Authenticator.Provider>
    </>
  )
}
export default App
