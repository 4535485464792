import { useRouter } from 'next/router'
import { useEnrollmentContext } from '@components/EnrollmentContext'
import { useClient } from '@api/enrollmentClient/config'
import { Enrollment } from '@clear-denver/tpc-openapi-typescript'

function useEnrollmentApi() {
  const router = useRouter()
  const hasTestQueryString = router?.query?.test === 'true'
  const client = useClient(hasTestQueryString) as Enrollment.EnrollmentStepsApi
  const {
    state,
    documentCheckCache,
    updateState,
    updateDocumentCheckCache,
    clearState,
  } = useEnrollmentContext()

  return {
    updateState,
    updateDocumentCheckCache,
    client,
    state,
    documentCheckCache,
    clearState,
    hasTestQueryString,
  }
}

export default useEnrollmentApi
