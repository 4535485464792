import React from 'react'

const StrokeIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    data-testid="stepper-stroke-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.381282 0.381282C0.72299 0.0395728 1.27701 0.0395728 1.61872 0.381282L7 5.76256L12.3813 0.381282C12.723 0.0395728 13.277 0.0395728 13.6187 0.381282C13.9604 0.72299 13.9604 1.27701 13.6187 1.61872L8.23744 7L13.6187 12.3813C13.9604 12.723 13.9604 13.277 13.6187 13.6187C13.277 13.9604 12.723 13.9604 12.3813 13.6187L7 8.23744L1.61872 13.6187C1.27701 13.9604 0.72299 13.9604 0.381282 13.6187C0.0395728 13.277 0.0395728 12.723 0.381282 12.3813L5.76256 7L0.381282 1.61872C0.0395728 1.27701 0.0395728 0.72299 0.381282 0.381282Z"
      fill="#FFF"
    />
  </svg>
)

export default StrokeIcon
