import { getAccessToken, getXCLEARCorrelationInfo } from '@api/utils'
import { DiscreteEvent, dispatchCustomEvent, KPI } from './customEvents'
import { Enrollment } from '@clear-denver/tpc-openapi-typescript'

export const handleSelectPlan = async (
  token: string,
  enrollmentClient: any,
  selectedPlan: string,
  affiliate: string | null,
  updateState: any
) => {
  const planSelectionCommand = {
    plan: selectedPlan,
  }
  const planSelectionCommandWithAffiliate = {
    ...planSelectionCommand,
    affiliate: affiliate,
  }
  const sanitizedPlanSelectionCommand = affiliate
    ? planSelectionCommandWithAffiliate
    : planSelectionCommand

  const xCLEARCorrelationInfo = getXCLEARCorrelationInfo()
  const baseMetaData = {
    kpi: KPI.USER_INTERACTION,
    xCLEARCorrelationInfo,
  }
  dispatchCustomEvent(Enrollment.LogEntryLevelEnum.INFO, {
    payload: {
      message: 'Start enrollment begin',
    },
    metadata: {
      ...baseMetaData,
      selectedPlan,
      planSelectionCommand: sanitizedPlanSelectionCommand,
      event: DiscreteEvent.START_ENROLLMENT_BEGIN,
    },
  })
  try {
    const { enrollment } = await enrollmentClient.startEnrollment({
      xCLEARCorrelationInfo,
      planSelectionCommand: sanitizedPlanSelectionCommand,
      authorization: token,
    })
    dispatchCustomEvent(Enrollment.LogEntryLevelEnum.INFO, {
      payload: {
        message: 'Start enrollment finished',
      },
      metadata: {
        ...baseMetaData,
        selectedPlan,
        planSelectionCommand: sanitizedPlanSelectionCommand,
        event: DiscreteEvent.START_ENROLLMENT_FINISHED,
      },
    })
    updateState(enrollment)
  } catch (error) {
    dispatchCustomEvent(Enrollment.LogEntryLevelEnum.ERROR, {
      payload: {
        message: error?.message,
        stack: error?.stack,
      },
      metadata: {
        event: DiscreteEvent.START_ENROLLMENT_ERROR,
        planSelectionCommand: sanitizedPlanSelectionCommand,
        ...baseMetaData,
      },
    })
  }
}

export const userHasAccessToken = () => {
  return getAccessToken() !== ''
}
