import React from 'react'

const USFlagIcon = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect y="0.5" width="16" height="11" fill="url(#pattern0)" />
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0" transform="scale(0.0625 0.0909091)" />
      </pattern>
      <image
        id="image0"
        width="16"
        height="11"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAAmklEQVQoFaWSQQ6DMAwE/RHgSpHg/fCnlksfEDSBQVHUNodGCmuvE+/GIrplTf2ypWFe8yYnln9PkZ6Pc+/jia+CCw6zarRpLv745AZc9gJxufdLTXXd6CK4iN3ShTm11robqOoMdIQD1VFVWe7rDHxW04GKNdIATkXRGYjRUmjVw04g7/2kJCdy1vh/B07Tjk4dHg70X6jdkh+HXubRABZ/gAAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
)

export default USFlagIcon
