import React from 'react'
import Logo from '@assets/svgs/logo'
import Link from 'next/link'

export const headerArgs = {
  links: [
    {
      text: 'Locations',
      href: '/locations',
      id: 'locations',
    },
    {
      text: 'Help Center',
      href: '/help',
      id: 'help',
    },
  ],
  dropdownLinks: [
    {
      text: 'Locations',
      href: '/locations',
      mobileOnly: true,
      id: 'locations',
    },
    {
      text: 'Help Center',
      href: '/help',
      mobileOnly: true,
      id: 'help-center',
    },
    {
      text: 'Check Status',
      href: '/status',
      id: 'status',
    },
    {
      text: 'Look Up KTN',
      href: '/ktn-lookup',
      id: 'ktn-lookup',
    },
  ],
  logo: (
    <Link href="/">
      <a aria-label="home page" data-cy="top-navigation-home">
        <Logo />
      </a>
    </Link>
  ),
}

if (process.env.NEXT_PUBLIC_HIDE_ENROLLMENT_FLOW === 'true') {
  const linksIndex = headerArgs.links.findIndex(
    (link) => link.text === 'Locations'
  )
  if (linksIndex !== -1) {
    headerArgs.links.splice(linksIndex, 1)
  }
  const dropdownLinksIndex = headerArgs.dropdownLinks.findIndex(
    (link) => link.text === 'Locations'
  )
  if (dropdownLinksIndex !== -1) {
    headerArgs.dropdownLinks.splice(dropdownLinksIndex, 1)
  }
}
