import React from 'react'
import { Flex, Box } from '@clear/design-system'

const Logo = () => (
  <Flex alignItems="center" width={['250px', null, '337px']}>
    <Box
      as="img"
      minWidth={0}
      mr={[4, null, 6]}
      src="/tsa-logo.png"
      alt="TSA logo"
    />
    <Box
      as="img"
      minWidth={0}
      src="/tsa-pre-logo.svg"
      alt="TSA pre-check logo"
    />
  </Flex>
)

export default Logo
