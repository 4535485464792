import React, { useEffect, useRef } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { CSSObject } from 'styled-components'

export const Map: React.FC<{
  center?: google.maps.LatLng | google.maps.LatLngLiteral | null
  locationMarkers?: Array<any>
  containerStyle?: CSSObject
  full?: boolean
  zoom?: number
}> = ({ center, locationMarkers, containerStyle, full, zoom }) => {
  const map = useRef<GoogleMap | null>(null)
  const dimensions = useRef<{ width: number; height: number }>({
    width: 300,
    height: 300,
  })

  /**
   * Dynamically adjust map dimensions to parent container when props.full == true
   */
  useEffect(() => {
    if (full && map?.current?.mapRef?.parentElement) {
      const { width, height } =
        map?.current?.mapRef?.parentElement?.getBoundingClientRect()
      dimensions.current = {
        width,
        height,
      }
    }
  }, [map, full])

  const handleMarkerClick = (address: string) => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
        address
      )}`,
      '_blank'
    )
  }

  return (
    <GoogleMap
      ref={map}
      mapContainerStyle={{
        width: full ? undefined : `${dimensions.current.width}px`,
        height: `${dimensions.current.height}px`,
        ...containerStyle,
      }}
      center={center ? center : undefined}
      zoom={zoom ? zoom : 11}
      id="google-map"
    >
      {locationMarkers?.map((location) => (
        <Marker
          key={location.airportCode}
          position={location.location}
          onClick={() => handleMarkerClick(location.address)}
        />
      ))}
      <></>
    </GoogleMap>
  )
}

Map.displayName = 'Map'
