import React, { InputHTMLAttributes } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Text,
  Button,
  DropdownGroup,
  DropdownOption,
  ColorType,
} from '@clear/design-system'
import { colors } from '../../../styles/theme'

export type Document = {
  file: File
  documentType?: string
  documentId?: string
  attachmentId?: string
}

export type Callback = (e: any) => void

export type DocumentUploadProps = BoxProps &
  InputHTMLAttributes<{
    variant?: string
  }> & {
    documentOptions?: Array<DropdownOption>
  }

export interface UploaderProps extends DropzoneOptions {
  disabled?: boolean
}

export const Uploader = ({
  disabled = false,
  accept,
  onDrop,
  maxFiles,
  validator,
  ...props
}: UploaderProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop,
    validator,
  })

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      maxWidth="content"
      p={10}
      borderWidth="input"
      borderStyle="dashed"
      borderColor="neutralsBorderGray"
      borderRadius="0.5rem"
      bg="neutralsGrayWash"
      opacity={disabled ? 0.4 : 1}
      {...props}
      {...getRootProps()}
    >
      <Icon
        name="upload-cloud"
        color={colors.brandDarkBlue as ColorType}
        size="1.5rem"
      />
      <Text as="div" fontSize={5} textAlign="center" mt={4} mb={1}>
        Upload files
      </Text>
      <Text fontSize={4} color="neutralsTextBlack" mb={1}>
        Drag and drop your files
      </Text>
      <Box mb={4} variant="regular" color="neutralsTextGray">
        Supports: png, jpeg, jpg
      </Box>
      <input
        data-cy="documentUploadInput"
        data-testid="upload-file"
        {...getInputProps()}
        disabled={disabled}
      />
      <Button
        variant="secondary"
        size="small"
        text="Browse"
        textProps={{ variant: 'regular' }}
        disabled={disabled}
      />
    </Flex>
  )
}

type DocumentCardType = {
  document: Document
  fieldName?: string
  isAccepted: boolean
  options?: Array<DropdownOption>
  onRemoveDocument?: (doc: Document, isAccepted: boolean) => void
  onDocumentTypeChange?: Callback
}

export const DocumentCard = ({
  document,
  fieldName,
  isAccepted = false,
  options = [],
  onRemoveDocument,
  onDocumentTypeChange,
}: DocumentCardType) => {
  return (
    <Box
      as="li"
      p={5}
      mb={5}
      bg="neutralsGrayWash"
      sx={{
        listStyle: 'none',
      }}
      data-cy="document-card"
    >
      <Flex alignItems="center" mb={4}>
        <Icon
          name="file"
          size="1.5rem"
          color={isAccepted ? 'neutralsTextBlack' : 'neutralsBorderGray'}
        />
        <Text
          ml={2}
          color={isAccepted ? 'neutralsTextBlack' : 'neutralsBorderGray'}
        >
          {document.file.name}
        </Text>
        <Box
          data-testid="remove-file"
          data-cy="remove-file"
          ml="auto"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            onRemoveDocument && onRemoveDocument(document, isAccepted)
          }}
        >
          <Icon name="x-circle" size="1.5rem" />
        </Box>
      </Flex>
      {isAccepted && (
        <DropdownGroup
          data-testid="document-type"
          name={`${fieldName}.documentType`}
          label="Document Type"
          placeholder="Select Document Type"
          value={document.documentType}
          options={options}
          maxWidth="content"
          onChange={onDocumentTypeChange}
        />
      )}
      {!isAccepted && (
        <Flex alignItems="center">
          <Text as="div">Invalid or Duplicate File.</Text>
          <Box ml={4}>
            <Icon
              name="alert-triangle"
              size="1.5rem"
              color="utilityDarkErrorRed"
            />
          </Box>
        </Flex>
      )}
    </Box>
  )
}

type DocumentListType = {
  documents: Array<Document>
  isAccepted?: boolean
  options?: Array<DropdownOption>
  onRemoveDocument?: (doc: Document, isAccepted: boolean) => void
  onDocumentTypeChange?: (doc: Document, type: string) => void
}
export const DocumentList = ({
  documents,
  isAccepted = false,
  options = [],
  onRemoveDocument,
  onDocumentTypeChange,
}: DocumentListType) => {
  return (
    <Box as="ul" maxWidth="content" p={0} mt={5} data-cy="documentList">
      {documents.map((document: Document, index: number) => (
        <DocumentCard
          key={document.file.name}
          fieldName={`acceptedDocuments[${index}]`}
          document={document}
          isAccepted={isAccepted}
          options={options}
          onRemoveDocument={
            onRemoveDocument
              ? () => onRemoveDocument(document, isAccepted)
              : undefined
          }
          onDocumentTypeChange={
            onDocumentTypeChange
              ? (e: any) => onDocumentTypeChange(document, e.target.value)
              : undefined
          }
        />
      ))}
    </Box>
  )
}

Uploader.displayName = 'Uploader'
