import React from 'react'
import Head from 'next/head'

type HeadTitleProps = {
  title: string
  description?: string
}

export const HeadTitle: React.FC<HeadTitleProps> = ({ title, description }) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="icon" href="favicon.svg" />
    <link rel="mask-icon" href="favicon.svg" color="#000000" />
  </Head>
)
